var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: { columns: _vm.columns, "data-source": _vm.schedule },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nTime(text)) + "\n  ")
          ])
        }
      },
      {
        key: "sessionLength",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.floatToHour(text)) + " Hours\n  ")
          ])
        }
      },
      {
        key: "dob",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nFormat(text)) + "\n  ")
          ])
        }
      },
      {
        key: "created_at",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nFormat(text)) + "\n  ")
          ])
        }
      },
      {
        key: "phone",
        fn: function(text) {
          return _c(
            "div",
            {},
            [text ? [_vm._v("+353 " + _vm._s(text))] : [_vm._v("N/A")]],
            2
          )
        }
      },
      {
        key: "status",
        fn: function(text) {
          return _c(
            "div",
            {},
            [
              text === "accepted"
                ? _c("a-tag", { attrs: { color: "#27ae60" } }, [
                    _vm._v("Active")
                  ])
                : _vm._e(),
              text === "invited"
                ? _c("a-tag", { attrs: { color: "#f39c12" } }, [
                    _vm._v("Invite Pending")
                  ])
                : _vm._e(),
              text === "requested"
                ? _c("a-tag", { attrs: { color: "#d35400" } }, [
                    _vm._v("Pending Club Acceptance")
                  ])
                : _vm._e(),
              text === "archived"
                ? _c("a-tag", { attrs: { color: "#d35400" } }, [
                    _vm._v("No Longer a Member")
                  ])
                : _vm._e(),
              text === "declined"
                ? _c("a-tag", { attrs: { color: "#c0392b" } }, [
                    _vm._v("Declined Invitation")
                  ])
                : _vm._e()
            ],
            1
          )
        }
      },
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              record.status === "requested"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(
                            record.id,
                            record.id,
                            "archived",
                            record.cmId
                          )
                        }
                      }
                    },
                    [_vm._v("\n      Cancel Join Request\n    ")]
                  )
                : _vm._e(),
              record.status === "accepted"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(
                            record.id,
                            record.id,
                            "archived",
                            record.cmId
                          )
                        }
                      }
                    },
                    [_vm._v("\n      Leave Club\n    ")]
                  )
                : _vm._e(),
              record.status === "invited"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-cyan",
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(
                            record.id,
                            record.id,
                            "accepted",
                            record.cmId
                          )
                        }
                      }
                    },
                    [_vm._v("\n      Accept Invite\n    ")]
                  )
                : _vm._e(),
              record.status === "invited"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-red",
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.userTableList(
                            record.id,
                            record.id,
                            "declined",
                            record.cmId
                          )
                        }
                      }
                    },
                    [_vm._v("\n      Decline Invite\n    ")]
                  )
                : _vm._e()
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }