var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Find a club to join" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 18 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Search for a club" },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchClubs }
                },
                [_vm._v("Search")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        _vm._l(_vm.clubs, function(club) {
          return _c(
            "a-row",
            { key: club.id, attrs: { type: "flex", gutter: 4 } },
            [
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v("\n        " + _vm._s(club.club_name) + "\n      ")
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _vm._v(" " + _vm._s(club.address) + "... ")
              ]),
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 6 } },
                [
                  !club.status ||
                  club.status === "declined" ||
                  club.status === "archived"
                    ? _c(
                        "a-button",
                        {
                          attrs: { block: "", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.requestToJoin(club.id)
                            }
                          }
                        },
                        [_vm._v("\n          Request to Join\n        ")]
                      )
                    : _vm._e(),
                  club.status === "invited" ||
                  club.status === "accepted" ||
                  club.status === "requested"
                    ? _c(
                        "a-button",
                        { attrs: { type: "danger", block: "", size: "small" } },
                        [
                          club.status === "invited"
                            ? [_vm._v("Invitation Pending")]
                            : _vm._e(),
                          club.status === "requested"
                            ? [_vm._v("Request Sent\n          ")]
                            : _vm._e(),
                          club.status === "accepted"
                            ? [_vm._v("Already a Member")]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }